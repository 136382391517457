<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        Birge Oqy
      </div>
      <div class="product-block-desc">
        «Birge Oqy» - это онлайн школа, нацеленная на академическую поддержку учащихся сельских школ.
      </div>
<!--      <div class="product-img-djoon-1" :style="{ 'background-image': `url('/css/images/DJOON-1.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/birge-back1.png"
          fit="fit"></el-image>

      <div class="product-block-desc">
        Проект представляет собой платформу для онлайн курсов с контентом в виде текстовых лекций, аудио, видео,
        презентаций, прохождением тестирования для закрепления материала, автоматическим обновлением прогресса,
        получением сертификатов, геймификацией и онлайн магазином призов.
      </div>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/birge-back2.png"
          fit="fit"></el-image>


    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
